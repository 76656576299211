import { SiteConfig } from "../sites.ts";

const DTMConfig: SiteConfig = {
  company: {
    name: "DigitalTwinMonitoring",
    tagline: "Bridging physical and virtual worlds through AI-powered digital twin technology and advanced sensor networks.",
    description: "Leaders in AI-driven digital twin monitoring and 3D modeling. Specializing in complex real-world scene analysis and virtualization. Delivering sensor-integrated virtual landscape monitoring solutions. Enabling data-driven decision making through digital twin technology.",
    details: `DigitalTwinMonitoring (DTM) is at the forefront of digital twin technology, combining advanced sensor networks with sophisticated 3D modeling to create accurate, real-time virtual representations of complex physical environments. Our innovative approach integrates AI processing to analyze and interpret both physical sensor data and virtual landscape information, enabling unprecedented insights and monitoring capabilities.

Our sensor integration platform combines data from multiple sources including IoT devices, environmental sensors, and spatial mapping systems to create comprehensive digital twins. These virtual replicas are continuously updated through our real-time processing pipeline, ensuring accurate representation of physical spaces and enabling sophisticated analysis of complex scenes and environments.

DTM's AI-powered analysis engine processes the combined sensor and 3D model data to identify patterns, predict changes, and detect anomalies across both physical and virtual landscapes. This capability enables organizations to monitor complex environments, predict maintenance needs, and optimize operations through data-driven insights derived from our digital twin implementations.

Through our advanced visualization and monitoring systems, we provide organizations with intuitive interfaces to interact with their digital twins, enabling better decision-making through real-time access to comprehensive environmental data. Our solutions serve industries ranging from manufacturing and infrastructure to urban planning and environmental monitoring.`,
    historical_narrative: [
      "Founded in 2023 to revolutionize environmental monitoring through digital twins",
      "Developed initial sensor integration platform for complex environments",
      "Launched SensorTwin platform with AI-powered analysis capabilities",
      "Established partnerships with leading IoT device manufacturers"
    ],
    mission: [
      "To bridge physical and virtual worlds through advanced digital twin technology",
      "To enable data-driven decision making through comprehensive environmental monitoring",
      "To revolutionize complex scene analysis through AI-powered digital twins",
      "To advance the field of sensor-integrated virtual monitoring"
    ],
    technology: {
      core_competencies: [
        {
          name: "Digital Twin Technology",
          areas: [
            "3D Modeling and Visualization",
            "Real-time Data Integration",
            "Sensor Network Management",
            "Environmental Monitoring",
            "Scene Analysis",
            "Virtual Reality Integration"
          ]
        },
        {
          name: "Sensor Systems",
          areas: [
            "IoT Device Integration",
            "Sensor Data Processing",
            "Network Management",
            "Calibration Systems",
            "Data Validation",
            "Real-time Monitoring"
          ]
        },
        {
          name: "AI Analysis",
          areas: [
            "Pattern Recognition",
            "Anomaly Detection",
            "Predictive Analytics",
            "Scene Understanding",
            "Environmental Modeling",
            "Risk Assessment"
          ]
        }
      ],
      development_stack: {
        frontend: ["Three.js", "WebGL", "React", "TypeScript"],
        backend: ["Python", "Go", "C++", "CUDA"],
        infrastructure: ["Kubernetes", "IoT Platform", "Edge Computing", "Cloud Services"]
      },
      innovation_areas: [
        {
          name: "Environmental Monitoring",
          focus: [
            "Complex Scene Analysis",
            "Real-time Data Processing",
            "Predictive Modeling",
            "Risk Assessment"
          ]
        },
        {
          name: "Digital Twin Systems",
          focus: [
            "3D Visualization",
            "Sensor Integration",
            "Virtual Reality",
            "Interactive Modeling"
          ]
        }
      ]
    },
    offerings: [
      {
        type: "product",
        name: "SensorTwin Platform",
        description: "Integrated sensor network and digital twin monitoring system",
        features: [
          "Multi-sensor data integration",
          "Real-time 3D modeling",
          "AI-powered scene analysis",
          "Environmental monitoring"
        ],
        benefits: [
          "Comprehensive environmental awareness",
          "Predictive maintenance capabilities",
          "Real-time monitoring and alerts",
          "Data-driven decision support"
        ],
        value_prop: [
          "Enable comprehensive environmental monitoring through digital twins",
          "Reduce operational costs through predictive maintenance",
          "Improve decision making with real-time environmental data",
          "Optimize resource allocation through AI-powered insights"
        ]
      },
      {
        type: "service",
        name: "Virtual Landscape Analytics",
        description: "AI-powered analysis of digital twin environments",
        features: [
          "Complex scene interpretation",
          "Pattern recognition and analysis",
          "Anomaly detection",
          "Predictive modeling"
        ],
        benefits: [
          "Enhanced operational insights",
          "Improved risk management",
          "Optimized resource allocation",
          "Proactive maintenance planning"
        ],
        value_prop: [
          "Gain deeper insights into environmental patterns and trends",
          "Identify and mitigate risks before they become problems",
          "Optimize operations through AI-powered analysis",
          "Enable data-driven decision making across organizations"
        ]
      }
    ],
    values: [
      "Technical Innovation",
      "Data Accuracy",
      "Environmental Understanding",
      "Operational Excellence"
    ],
    beliefs: [
      "Digital twins enable better decisions",
      "Sensor integration drives insight",
      "AI enhances environmental understanding",
      "Real-time monitoring enables proactive management"
    ],
    norms: [
      "Data-driven approach",
      "Continuous monitoring",
      "Environmental awareness",
      "Technical precision"
    ],
    partnerships: [
      {
        company: "IoTech Solutions",
        role: "Strategic partner for IoT device integration and sensor networks"
      },
      {
        company: "EnviroSense Systems",
        role: "Environmental sensor technology provider and integration partner"
      },
      {
        company: "VirtualTech Labs",
        role: "Research partner for advanced visualization technologies"
      }
    ],
    team: [
      {
        name: "Dr. Sarah Chen",
        role: "Chief Executive Officer",
        background: "PhD in Computer Vision with expertise in digital twin technologies"
      },
      {
        name: "Marcus Rodriguez",
        role: "Chief Technology Officer",
        background: "20 years experience in sensor networks and IoT systems"
      },
      {
        name: "Dr. James Wilson",
        role: "Head of AI Research",
        background: "Leading expert in environmental modeling and machine learning"
      }
    ],
    contact: {
      email: "info@digitaltwinmonitoring.com",
      phone: "+1 (416) 555-0123"
    },
    image_prompts: [
      "Sophisticated 3D visualization of a digital twin environment with real-time sensor data overlay",
      "Modern control room showing multiple digital twin monitoring displays and environmental data",
      "Technical illustration of sensor network integration with digital twin architecture",
      "Split-screen showing physical environment alongside its digital twin representation",
      "Professional team analyzing complex environmental data through digital twin interface",
      "Abstract visualization of AI-powered pattern recognition in digital twin systems",
      "Detailed 3D model showing sensor placement and data flow in monitored environment",
      "Interactive digital twin dashboard with real-time environmental metrics",
      "Virtual reality interface for digital twin exploration and analysis",
      "Technical diagram of sensor network architecture with data processing workflow"
    ]
  },
  theme: {
    primary: "#2C3E50",
    secondary: "#34495E",
    accent: "#3498DB",
    background: "#ECF0F1",
    text: "#2C3E50"
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: false,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Technology", href: "/technology" },
    { label: "Solutions", href: "/solutions" },
    { label: "Contact", href: "/contact" }
  ],
  aboutPage: {
    title: "Digital Twin Innovation",
    subtitle: "Leading the Future of Environmental Monitoring",
    mission: {
      title: "Our Mission",
      content: "To bridge physical and virtual worlds through advanced digital twin technology, enabling data-driven decision making through comprehensive environmental monitoring."
    },
    team: [
      {
        name: "Dr. Sarah Chen",
        role: "Chief Executive Officer",
        background: "PhD in Computer Vision with expertise in digital twin technologies"
      },
      {
        name: "Marcus Rodriguez",
        role: "Chief Technology Officer",
        background: "20 years experience in sensor networks and IoT systems"
      },
      {
        name: "Dr. James Wilson",
        role: "Head of AI Research",
        background: "Leading expert in environmental modeling and machine learning"
      }
    ],
    values: [
      {
        title: "Technical Innovation",
        description: "Pushing the boundaries of digital twin technology"
      },
      {
        title: "Data Accuracy",
        description: "Ensuring precise and reliable environmental monitoring"
      },
      {
        title: "Environmental Understanding",
        description: "Deep insights through comprehensive analysis"
      },
      {
        title: "Operational Excellence",
        description: "Delivering superior monitoring solutions"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Advanced Digital Twin Solutions",
    markdown: `
# Digital Twin Technology Platform

Our comprehensive digital twin platform combines advanced sensor networks with sophisticated 3D modeling to create accurate, real-time virtual representations of complex physical environments.

## Core Technologies

- Real-time sensor integration
- AI-powered analysis
- 3D visualization
- Environmental monitoring
- Predictive analytics

## Development Stack

### Frontend
- Three.js
- WebGL
- React
- TypeScript

### Backend
- Python
- Go
- C++
- CUDA

### Infrastructure
- Kubernetes
- IoT Platform
- Edge Computing
- Cloud Services
    `
  },
  solutionsPage: {
    title: "Solutions",
    subtitle: "Comprehensive Digital Twin Monitoring",
    content: "Our solutions enable organizations to create and manage sophisticated digital twins of their physical environments, providing real-time insights and predictive capabilities."
  },
  homePage: {
    hero: {
      title: "Digital Twin Monitoring",
      highlight: "AI-Powered Environmental Intelligence",
      description: "Advanced sensor networks and digital twin technology for comprehensive environmental monitoring and analysis."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};

export default DTMConfig;
