import { SiteConfig } from "../sites.ts";

const SIIConfig: SiteConfig = {
  company: {
    name: "Sightline Innovation Inc.",
    tagline: "Pioneering Canadian AI innovation through strategic intellectual property and groundbreaking machine learning patents.",
    description: "Early Canadian pioneer in artificial intelligence and machine learning. Strategic patent portfolio in IoT and data processing technologies. Leaders in human-in-the-loop AI system innovations. Established intellectual property holding company since 2012.",
    details: "Founded in 2012, Sightline Innovation Inc. emerged as one of Canada's first artificial intelligence startups, establishing itself at the forefront of machine learning innovation. As an early mover in the AI space, the company developed and secured crucial patents in key areas of machine learning, data processing, and Internet of Things (IoT) technologies.\n\nThe company's strategic patent portfolio encompasses fundamental innovations in human-in-the-loop AI systems, which have become increasingly valuable as artificial intelligence becomes more prevalent in enterprise applications. These patents cover critical aspects of how humans interact with and guide AI systems, ensuring more reliable and controllable AI outcomes.\n\nSightline's intellectual property holdings include significant innovations in IoT data processing and machine learning applications, representing foundational technologies that underpin many modern AI implementations. The company's patents address crucial aspects of data handling, processing methodologies, and machine learning optimization techniques.\n\nToday, Sightline Innovation Inc. operates as a specialized intellectual property holding company, managing and licensing its strategic portfolio of AI and machine learning patents. The company's IP portfolio continues to influence the development of AI technologies, particularly in areas requiring human oversight and interaction with automated systems.",
    offerings: [
      {
        type: "product",
        name: "AI Patent Portfolio",
        description: "Strategic portfolio of machine learning and AI patents",
        features: [
          "IoT data processing patents",
          "Human-in-the-loop AI systems",
          "Machine learning optimization methods",
          "Data handling and processing techniques"
        ],
        benefits: [
          "Protected IP rights",
          "Innovation protection",
          "Strategic licensing opportunities",
          "Technology implementation guidance"
        ],
        value_prop: [
          "Access foundational AI and ML patents",
          "Implement protected human-in-the-loop systems",
          "Leverage proven IoT processing technologies",
          "Reduce R&D costs through strategic licensing"
        ]
      },
      {
        type: "service",
        name: "IP Licensing Program",
        description: "Strategic licensing of AI and machine learning patents",
        features: [
          "Patent licensing agreements",
          "Technology transfer support",
          "Implementation consultation",
          "IP portfolio management"
        ],
        benefits: [
          "Access to pioneering AI technologies",
          "Protected innovation implementation",
          "Reduced R&D costs",
          "Strategic IP advantages"
        ],
        value_prop: [
          "Accelerate AI development with proven patents",
          "Ensure protected implementation of key technologies",
          "Optimize R&D investment through licensing",
          "Gain strategic advantage in AI markets"
        ]
      }
    ],
    values: [
      "Innovation Leadership",
      "Technological Excellence",
      "Strategic IP Management",
      "Collaborative Progress"
    ],
    beliefs: [
      "AI innovation requires strong IP protection",
      "Human-AI interaction is crucial for success",
      "Strategic patents drive industry progress",
      "Canadian leadership in AI innovation"
    ],
    norms: [
      "Strategic IP development",
      "Innovation protection",
      "Collaborative licensing",
      "Technology advancement"
    ],
    historical_narrative: [
      "Founded in 2012 as one of Canada's first AI startups",
      "Developed initial machine learning patents for industrial applications",
      "Pioneered human-in-the-loop AI systems with groundbreaking patents",
      "Established strategic patent portfolio in IoT and data processing",
      "Transitioned to specialized IP holding company model",
      "Licensed key patents to major technology companies",
      "Expanded portfolio to cover emerging AI applications",
      "Formed strategic partnership with DataAsset.Store for human-in-the-loop technology"
    ],
    mission: [
      "To protect and monetize pioneering innovations in artificial intelligence and machine learning",
      "To advance the development of human-centric AI systems through strategic IP licensing",
      "To ensure Canadian leadership in AI technology through strategic patent management",
      "To enable responsible AI adoption through human-in-the-loop technology patents"
    ],
    image_prompts: [
      "Timeline visualization showing key AI patent milestones from 2012-2024, with elegant floating patent documents and technology icons against a clean white background",
      "Professional 3D rendering of human-in-the-loop AI system architecture, showing seamless interaction between human operators and AI processes with glowing connection points",
      "Clean, minimalist illustration of IoT data processing patents with connected devices and data flow visualization in Sightline's brand colors",
      "Modern office environment with holographic displays showing AI patent analytics and portfolio growth metrics",
      "Abstract representation of machine learning optimization methods covered by patents, using geometric shapes and neural network visualizations",
      "Sophisticated diagram showing the integration of human oversight in AI systems, highlighting key patented control mechanisms",
      "Professional team reviewing digital patent documentation with augmented reality overlays showing technical specifications",
      "Dynamic 3D visualization of Sightline's patent portfolio coverage areas across different AI and ML technologies",
      "Artistic interpretation of data processing innovations using flowing lines and interconnected nodes in a corporate tech style",
      "Clean, technical illustration of patent-protected AI system architecture with highlighted innovation points and reference numbers"
    ]
  },
  theme: {
    primary: "#2C3E50",
    secondary: "#34495E",
    accent: "#3498DB",
    background: "#ECF0F1",
    text: "#2C3E50"
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: false,
    showNews: true,
    showHelp: false,
    showAuth: false
  },
  footerLinks: [
    {
      label: "Patents",
      href: "/patents"
    },
    {
      label: "Licensing",
      href: "/licensing"
    },
    {
      label: "Contact",
      href: "/contact"
    },
    {
      label: "Privacy Policy",
      href: "/privacy"
    }
  ],
  aboutPage: {
    title: "About Sightline Innovation",
    subtitle: "Pioneering AI Innovation Through Strategic IP",
    mission: {
      title: "Our Mission",
      content: "To protect and advance pioneering innovations in artificial intelligence while enabling responsible AI adoption through strategic patent management and licensing."
    },
    values: [
      {
        title: "Innovation Leadership",
        description: "Driving the future of AI through strategic patent development and protection"
      },
      {
        title: "Technological Excellence",
        description: "Maintaining the highest standards in AI and machine learning innovation"
      },
      {
        title: "Strategic IP Management",
        description: "Protecting and leveraging intellectual property to advance AI technology"
      },
      {
        title: "Collaborative Progress",
        description: "Working with partners to implement protected AI innovations responsibly"
      }
    ],
    team: []
  },
  technologyPage: {
    title: "Our Patent Portfolio",
    subtitle: "Strategic AI and Machine Learning Patents",
    markdown: "# Innovation Portfolio\n\nOur patent portfolio covers crucial aspects of AI technology:\n\n- Human-in-the-loop AI systems\n- IoT data processing\n- Machine learning optimization\n- Data handling methodologies"
  },
  solutionsPage: {
    title: "Licensing Solutions",
    subtitle: "Strategic IP Implementation",
    content: "Access our portfolio of AI and machine learning patents through flexible licensing programs designed to meet your innovation needs."
  },
  homePage: {
    hero: {
      title: "Strategic AI Innovation",
      highlight: "Protected by Patents",
      description: "Access pioneering AI and machine learning patents through strategic licensing partnerships."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};

export default SIIConfig;
