import { SiteConfig } from "../sites.ts";

const ZSConfig: SiteConfig = {
  company: {
    name: "ZeroServer.org",
    tagline: "Championing open AI systems and data sovereignty through vendor-neutral consulting and mesh network solutions.",
    description: "Leading advocate for open-source AI systems and data sovereignty. Specializing in vendor compliance and management consulting. Delivering mesh network solutions for distributed AI computing. Protecting enterprise value through open AI architectures.",
    details: `ZeroServer.org stands at the forefront of the movement to protect data sovereignty and enterprise value through open AI systems and mesh networking solutions. As US hyperscalers and the CLOUD Act increasingly threaten organizational data sovereignty, we provide crucial guidance and solutions that enable organizations to maintain control of their AI operations and data assets.

Our consulting practice specializes in vendor management and compliance, helping organizations navigate the complex landscape of AI providers while maintaining data sovereignty. We focus on implementing open-source AI solutions and mesh networks that prevent the erosion of enterprise value typically associated with dependency on proprietary US-based language models and cloud services.

Through our expertise in mesh networking and distributed systems, we enable organizations to build resilient, sovereign AI infrastructure that operates independently of centralized cloud providers. Our solutions leverage open-source AI models and distributed computing architectures to create robust, scalable systems that maintain full organizational control over data and AI operations.

ZeroServer.org actively advocates for open AI systems and data sovereignty rights, working to educate organizations about the risks of data value erosion through US hyperscaler dependencies. We provide strategic guidance on building vendor-neutral AI architectures that protect organizational interests while enabling full participation in the AI revolution.`,
    offerings: [
      {
        type: "service",
        name: "Open AI Consulting",
        description: "Strategic consulting for open-source AI implementation and vendor management",
        features: [
          "Open AI architecture design",
          "Vendor compliance assessment",
          "Data sovereignty protection",
          "Risk mitigation strategies"
        ],
        benefits: [
          "Protected data sovereignty",
          "Reduced vendor lock-in",
          "Enhanced data value protection",
          "Compliant AI operations"
        ]
      },
      {
        type: "service",
        name: "Mesh Network Solutions",
        description: "Distributed computing infrastructure for sovereign AI operations",
        features: [
          "Mesh network design",
          "Distributed AI deployment",
          "Open-source integration",
          "Sovereignty protection"
        ],
        benefits: [
          "Independent infrastructure",
          "Distributed control",
          "Enhanced resilience",
          "Protected data paths"
        ]
      }
    ],
    values: [
      "Data Sovereignty",
      "Open Source Advocacy",
      "Vendor Independence",
      "Technical Freedom"
    ],
    beliefs: [
      "Open AI systems protect enterprise value",
      "Data sovereignty is non-negotiable",
      "Vendor independence enables innovation",
      "Distributed systems enhance control"
    ],
    norms: [
      "Open-source first",
      "Vendor-neutral approach",
      "Sovereignty protection",
      "Distributed architecture"
    ],
    contact: {
      email: "info@metadataassetmanagement.com",
      phone: "+1 (416) 817-6499"
    },
    image_prompts: [
      "Isometric visualization of a decentralized mesh network with interconnected nodes glowing with green energy, representing sovereign AI infrastructure",
      "Abstract representation of data sovereignty showing a shield protecting flowing data streams from external access, with maple leaf motifs",
      "Modern office setting with professionals reviewing distributed system architectures on transparent displays, highlighting vendor-neutral solutions",
      "Technical illustration of open-source AI architecture with clear separation boundaries and distributed processing nodes",
      "Dynamic 3D visualization of a mesh network in action, showing data flowing through independent nodes rather than central servers",
      "Professional team working with holographic displays showing open AI system components and sovereignty protection metrics",
      "Artistic interpretation of breaking free from vendor lock-in, showing traditional cloud symbols transforming into distributed mesh patterns",
      "Clean, minimal diagram of sovereign computing infrastructure with emphasis on data control points and security measures",
      "Split-screen visualization comparing centralized versus distributed AI operations, highlighting the benefits of independence",
      "Futuristic command center environment showing real-time monitoring of distributed AI operations with sovereignty indicators"
    ],
    historical_narrative: [
      "Founded in 2023 to protect organizations from data sovereignty threats",
      "Established core open-source AI consulting practice",
      "Developed comprehensive mesh networking solutions for distributed AI",
      "Launched vendor compliance assessment framework",
      "Created strategic partnership with DataAsset.Store for sovereign computing",
      "Expanded advocacy efforts for open AI systems and data rights"
    ],
    mission: [
      "To champion open AI systems and protect organizational data sovereignty",
      "To enable vendor-neutral AI architectures that preserve enterprise value",
      "To advance distributed computing solutions for sovereign operations",
      "To educate organizations about data sovereignty risks and solutions"
    ]
  },
  theme: {
    primary: "#2C3E50",
    secondary: "#34495E",
    accent: "#27AE60",
    background: "#ECF0F1",
    text: "#2C3E50"
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: false,
    showNews: true,
    showHelp: true,
    showAuth: false
  },
  footerLinks: [
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" },
    { label: "Contact", href: "/contact" }
  ],
  aboutPage: {
    title: "About ZeroServer.org",
    subtitle: "Protecting Data Sovereignty Through Open AI Systems",
    mission: {
      title: "Our Mission",
      content: "To champion open AI systems and protect organizational data sovereignty through vendor-neutral consulting and mesh network solutions."
    },
    values: [
      {
        title: "Data Sovereignty",
        description: "We believe in absolute control over organizational data assets."
      },
      {
        title: "Open Source Advocacy",
        description: "We champion open AI systems that preserve enterprise value."
      },
      {
        title: "Vendor Independence",
        description: "We enable freedom from proprietary AI system lock-in."
      },
      {
        title: "Technical Freedom",
        description: "We empower organizations with sovereign computing capabilities."
      }
    ],
    team: []
  },
  solutionsPage: {
    title: "Sovereign AI Solutions",
    subtitle: "Comprehensive Solutions for Data Sovereign AI Operations",
    content: "Our solutions enable organizations to maintain complete control over their AI operations and data assets through open-source implementations and distributed architectures."
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Open Source AI & Mesh Network Infrastructure",
    markdown: "# Sovereign Computing Infrastructure\n\nOur technology stack is built on open-source principles and distributed architectures, enabling true data sovereignty and operational independence."
  },
  homePage: {
    hero: {
      title: "Champion Your Data Sovereignty",
      highlight: "Open AI Systems",
      description: "Protect your organization's value through vendor-neutral AI solutions and mesh network infrastructure."
    },
    config: {
      showPromoVideo: false,
      showContactForm: true
    }
  }
};

export default ZSConfig;
