import type { SiteConfig } from "../sites.ts";

const DASConfig: SiteConfig = {
  company: {
    name: "DataAsset.Store",
    tagline: "Protecting and maximizing enterprise value through sovereign AI data management and compliance solutions.",
    description: "Leading provider of AI data sovereignty and management solutions. Specializing in protecting enterprise value in the AI era. Delivering secure LLM frameworks and sovereign network solutions. Enabling organizations to maintain control of their AI data assets.",
    details: `DataAsset.Store (DAS) stands at the forefront of the AI revolution, providing crucial solutions for managing and protecting enterprise data in the age of large language models and AI agents. Our innovative DaLLM framework enables organizations to maintain sovereignty over their valuable data while leveraging the power of AI and LLMs.

Through our sovereign network technology, we enable organizations to efficiently utilize their internal compute resources for AI applications while maintaining complete control over their data flows. Our solutions create private networks that optimize local compute capacity for AI model execution, with selective capability to leverage external frontier models when needed.

We recognize that as AI becomes more integrated into business operations, the data flowing through AI systems becomes a core part of enterprise value. Our solutions protect this value by ensuring organizations maintain ownership of their data paths, customer relationships, and domain expertise. This is particularly crucial for professional services firms, such as law offices, that need to leverage AI while preserving their client relationships and expertise.

DAS provides specialized packaging for government and regulated industries, combining sovereign computing capabilities with comprehensive compliance features. Our solutions include governance analysis tools and instantaneous metrics, ensuring organizations can safely adopt AI technologies while protecting their core business value from erosion.`,
    offerings: [
      {
        type: "product",
        name: "DaLLM Framework",
        description: "Enterprise framework for managing data in large language model applications",
        features: [
          "Sovereign networks for private infrastructure",
          "On-premise computing optimization",
          "Selective frontier model access",
          "Model fine-tuning capabilities",
          "Algorithmic processing enhancement"
        ],
        benefits: [
          "Maintained data sovereignty",
          "Enhanced accuracy for business problems",
          "Lower operational costs",
          "Protected enterprise value"
        ],
        value_prop: [
          "Maintain complete control over AI data assets",
          "Optimize compute resources while preserving data sovereignty",
          "Reduce costs through efficient local processing",
          "Protect valuable client relationships and domain expertise"
        ]
      },
      {
        type: "product",
        name: "SAICO Platform",
        description: "Sovereign AI Operations and Compliance platform for enterprise governance",
        features: [
          "Real-time AI operations monitoring",
          "Automated compliance checking",
          "Sovereign computing infrastructure",
          "Comprehensive audit trails",
          "Customizable compliance frameworks",
          "SOC1, SOC2, and ISO42001 compliance support"
        ],
        benefits: [
          "Complete operational oversight",
          "Regulatory compliance",
          "Secure governance controls",
          "Adaptable to different jurisdictions"
        ],
        value_prop: [
          "Ensure comprehensive compliance in AI operations",
          "Maintain full visibility and control over AI systems",
          "Adapt to evolving regulatory requirements",
          "Protect enterprise value through robust governance"
        ]
      },
      {
        type: "product",
        name: "Human-in-the-Loop AI System",
        description: "Licensed technology from Sightline Innovation for AI systems requiring human oversight",
        features: [
          "Machine learning analysis pathways",
          "Message path escalation",
          "Human operator interaction systems",
          "Decision-making routing"
        ],
        benefits: [
          "Controlled AI deployment",
          "Appropriate human oversight",
          "Enhanced decision management",
          "Regulatory compliance"
        ],
        value_prop: [
          "Maintain human control over AI systems",
          "Ensure appropriate oversight of AI decisions",
          "Balance automation with human judgment",
          "Meet regulatory requirements for human supervision"
        ]
      }
    ],
    values: [
      "Data Sovereignty",
      "Enterprise Value Protection",
      "Innovation Control",
      "Compliance Excellence"
    ],
    beliefs: [
      "Enterprise data is core business value",
      "AI integration must protect sovereignty",
      "Data control enables innovation",
      "Compliance enables trust"
    ],
    norms: [
      "Data-sovereign approach",
      "Value preservation",
      "Security-first mindset",
      "Controlled innovation"
    ],
    partnerships: [
      {
        company: "Sightline Innovation Inc.",
        role: "Technology licensing partner for human-in-the-loop AI patents"
      },
      {
        company: "ZeroServer.org",
        role: "Strategic partner for open-source AI infrastructure and mesh networking"
      },
      {
        company: "GlobalDevOps Inc.",
        role: "Implementation partner for global deployment and operations"
      }
    ],
    homepage_video_url: "https://storage.googleapis.com/public-das-web-assets/das-consulting.mp4",
    historical_narrative: [
      "Founded in 2023 to address growing concerns about AI data sovereignty",
      "Developed DaLLM framework in collaboration with leading Canadian institutions",
      "Launched SAICO platform for enterprise governance in Q4 2023",
      "Established strategic partnership with Sightline Innovation for human-in-the-loop AI technology"
    ],
    mission: [
      "To protect and maximize enterprise value in the AI era through sovereign data management",
      "To enable organizations to leverage AI while maintaining control of their data assets",
      "To ensure data sovereignty and compliance in AI operations",
      "To preserve client relationships and domain expertise in professional services"
    ],
    technology: {
      core_competencies: [
        {
          name: "Data Sovereignty",
          areas: [
            "Sovereign Computing Networks",
            "Data Flow Control Systems",
            "Access Management",
            "Data Lineage Tracking",
            "Privacy-Preserving Computing",
            "Secure Data Enclaves"
          ]
        },
        {
          name: "AI Management",
          areas: [
            "LLM Integration Framework",
            "Model Governance",
            "AI Operations Monitoring",
            "Compliance Automation",
            "Human-in-the-Loop Systems",
            "Model Performance Analytics"
          ]
        },
        {
          name: "Enterprise Security",
          areas: [
            "Zero-Trust Architecture",
            "Audit Trail Systems",
            "Encryption Management",
            "Access Control",
            "Threat Detection",
            "Compliance Reporting"
          ]
        }
      ],
      development_stack: {
        frontend: [
          "Vue.js",
          "TypeScript",
          "D3.js",
          "WebGL"
        ],
        backend: [
          "Python",
          "Rust",
          "Java",
          "Go"
        ],
        infrastructure: [
          "Kubernetes",
          "Docker",
          "Private Cloud",
          "Sovereign Networks"
        ],
        security: [
          "Zero-Trust Framework",
          "HSM Integration",
          "PKI Infrastructure",
          "Secure Enclaves"
        ]
      },
      innovation_areas: [
        {
          name: "Sovereign Computing",
          focus: [
            "Private AI Infrastructure",
            "Secure Model Deployment",
            "Data Flow Control",
            "Resource Optimization"
          ]
        },
        {
          name: "Compliance Automation",
          focus: [
            "Real-time Monitoring",
            "Automated Reporting",
            "Policy Enforcement",
            "Audit Trails"
          ]
        },
        {
          name: "Enterprise AI Integration",
          focus: [
            "Secure LLM Access",
            "Model Governance",
            "Performance Analytics",
            "Risk Management"
          ]
        }
      ]
    },
    team: [
      {
        name: "Abdul Razzak Syed",
        role: "Chief Executive Officer",
        background: "CPA with extensive experience in compliance and enterprise governance"
      },
      {
        name: "Wallace Trenholm",
        role: "Chief Technology Officer",
        background: "Veteran AI practitioner with over 10 years of experience in artificial intelligence and data systems"
      },
      {
        name: "Hugh O'Reilly",
        role: "Director",
        background: "Expert in pension fund management with deep understanding of data management requirements in institutional settings"
      },
      {
        name: "Mai Mavinkurve",
        role: "Director",
        background: "Data Governance expert leading initiatives to implement P.Eng certification standards in AI infrastructure"
      }
    ]
  },
  theme: {
    primary: "#0066CC",
    secondary: "#001F3F",
    accent: "#00A3E0",
    background: "#FFFFFF",
    text: "#333333"
  },
  navigation: {
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" },
    { label: "Contact", href: "/contact" }
  ],
  aboutPage: {
    title: "About DataAsset.Store",
    subtitle: "Protecting Enterprise Value in the AI Era",
    mission: {
      title: "Our Mission",
      content: "To protect and maximize enterprise value in the AI era through sovereign data management and compliance solutions."
    },
    team: [
      {
        name: "Abdul Razzak Syed",
        role: "Chief Executive Officer",
        background: "CPA with extensive experience in compliance and enterprise governance"
      },
      {
        name: "Wallace Trenholm",
        role: "Chief Technology Officer",
        background: "Veteran AI practitioner with over 10 years of experience in artificial intelligence and data systems"
      },
      {
        name: "Hugh O'Reilly",
        role: "Director",
        background: "Expert in pension fund management with deep understanding of data management requirements in institutional settings"
      },
      {
        name: "Mai Mavinkurve",
        role: "Director",
        background: "Data Governance expert leading initiatives to implement P.Eng certification standards in AI infrastructure"
      }
    ],
    values: [
      {
        title: "Data Sovereignty",
        description: "Ensuring organizations maintain complete control over their valuable data assets"
      },
      {
        title: "Enterprise Value Protection",
        description: "Safeguarding core business value in the age of AI"
      },
      {
        title: "Innovation Control",
        description: "Enabling controlled innovation while maintaining data security"
      },
      {
        title: "Compliance Excellence",
        description: "Setting the standard for AI governance and regulatory compliance"
      }
    ]
  },
  homePage: {
    hero: {
      title: "Sovereign AI Data Management",
      highlight: "Protect Your Enterprise Value",
      description: "Maintain complete control over your AI data assets while leveraging the power of large language models"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  },
  media: {
    promoVideo: "https://storage.googleapis.com/public-das-web-assets/das-consulting.mp4"
  }
};

export default DASConfig;
