import { SiteConfig } from "../sites.ts";

const CTIOConfig: SiteConfig = {
  company: {
    name: "ChainTokenomics.IO",
    tagline: "Revolutionizing corporate finance through blockchain-based deferred revenue tokens and regulated equity trading solutions.",
    description: "Pioneering deferred revenue tokenization on Ethereum blockchain. Enabling revenue-based funding alternatives to equity investment. Facilitating compliant digital equity trading for accredited investors. Delivering blockchain solutions for modern corporate finance.",
    details: `ChainTokenomics.IO (CTIO) is transforming corporate finance through innovative blockchain solutions built on Ethereum. Our flagship Deferred Revenue Token (DRT) platform enables companies to tokenize future revenue commitments, creating a revolutionary funding mechanism that serves as an alternative to traditional equity investment. This approach allows companies to maintain ownership while accessing growth capital through future revenue commitments.

Our DRT platform leverages smart contracts to create a transparent, automated system for managing deferred revenue agreements. Companies can tokenize future revenue commitments, which investors can purchase, creating immediate capital while maintaining full equity ownership. The platform includes comprehensive tracking, automated revenue sharing, and complete audit trails of all transactions and distributions.

ShareTwin, our second major offering, revolutionizes private equity trading by leveraging Ethereum blockchain technology within regulatory frameworks. The platform ensures compliance with securities regulations by incorporating robust AML/KYC verification and accredited investor certification directly into the smart contracts, enabling secure and compliant trading of private equity shares.

Through our innovative use of blockchain technology, we're creating new possibilities in corporate finance that combine the efficiency and transparency of blockchain with full regulatory compliance. Our solutions enable companies to access capital markets in novel ways while maintaining complete regulatory compliance and reducing traditional friction points in private equity markets.`,
    offerings: [
      {
        type: "product",
        name: "Deferred Revenue Token (DRT) Platform",
        description: "Blockchain-based platform for tokenizing and trading future revenue commitments",
        features: [
          "Smart contract-based revenue commitments",
          "Automated revenue distribution",
          "Real-time tracking and reporting",
          "Transparent audit trails"
        ],
        benefits: [
          "Alternative to equity funding",
          "Maintained ownership control",
          "Automated compliance",
          "Reduced administrative overhead"
        ],
        value_prop: [
          "Access growth capital without diluting equity",
          "Automate revenue sharing through smart contracts",
          "Reduce administrative costs through blockchain automation",
          "Enable transparent and efficient capital raising"
        ]
      },
      {
        type: "product",
        name: "ShareTwin",
        description: "Compliant blockchain platform for private equity trading",
        features: [
          "Integrated AML/KYC verification",
          "Accredited investor certification",
          "Smart contract-based trading",
          "Regulatory compliance automation"
        ],
        benefits: [
          "Compliant equity trading",
          "Reduced transaction friction",
          "Enhanced market liquidity",
          "Automated compliance verification"
        ],
        value_prop: [
          "Trade private equity shares with full regulatory compliance",
          "Reduce transaction costs through automation",
          "Increase market liquidity for private shares",
          "Enable efficient secondary market trading"
        ]
      }
    ],
    values: [
      "Financial Innovation",
      "Regulatory Compliance",
      "Technological Excellence",
      "Market Efficiency"
    ],
    beliefs: [
      "Blockchain can revolutionize corporate finance",
      "Compliance and innovation can coexist",
      "Revenue-based funding provides better options",
      "Technology should reduce market friction"
    ],
    norms: [
      "Regulatory-first approach",
      "Continuous innovation",
      "Security-focused development",
      "Transparent operations"
    ],
    image_prompts: [
      "Modern financial dashboard displaying blockchain transactions and revenue token metrics with elegant blue and gold visualization",
      "Abstract 3D representation of tokenized revenue streams flowing through Ethereum blockchain structures",
      "Professional trading interface showing compliant digital equity trading with regulatory indicators and verification symbols",
      "Isometric illustration of smart contracts managing automated revenue distribution with connected nodes",
      "Corporate boardroom setting with holographic displays showing real-time DRT platform analytics",
      "Clean, minimal visualization of blockchain-based equity trading with regulatory compliance checkpoints",
      "Abstract representation of deferred revenue tokens with flowing digital assets and secure transaction paths",
      "Professional team reviewing digital equity trading data on multiple transparent screens in modern office setting",
      "3D visualization of the ShareTwin platform showing secure trading pathways and compliance verification steps",
      "Artistic interpretation of blockchain-based financial innovation with interconnected networks and secure transaction flows"
    ],
    historical_narrative: [
      "Founded in 2023 as a response to the growing need for alternative funding mechanisms in the Canadian technology sector",
      "Developed initial DRT platform concept through collaboration with leading financial institutions",
      "Successfully completed first deferred revenue token issuance in Q4 2023",
      "Launched ShareTwin platform in Q2 2024 to address private equity trading needs"
    ],
    mission: [
      "To revolutionize corporate finance through innovative blockchain solutions",
      "To provide companies with alternatives to traditional equity financing",
      "To enable compliant and efficient private equity trading",
      "To reduce friction in capital markets through technology"
    ]
  },
  theme: {
    primary: "#1a237e",    // Deep blue representing trust and professionalism
    secondary: "#ffd700",  // Gold representing financial services
    accent: "#4a90e2",     // Lighter blue for blockchain/tech aspect
    background: "#ffffff", // Clean white background
    text: "#2c3e50"       // Dark gray text for readability
  },
  navigation: {
    portfolioLabel: "Solutions",
    showTechnology: true,
    showSolutions: true,
    showBlog: true,
    showNews: true,
    showHelp: true,
    showAuth: true
  },
  footerLinks: [
    { label: "Privacy Policy", href: "/privacy" },
    { label: "Terms of Service", href: "/terms" },
    { label: "Contact", href: "/contact" },
    { label: "About", href: "/about" }
  ],
  aboutPage: {
    title: "About ChainTokenomics.IO",
    subtitle: "Transforming Corporate Finance Through Blockchain Innovation",
    mission: {
      title: "Our Mission",
      content: "To revolutionize corporate finance by providing innovative blockchain-based solutions that enable companies to access capital markets efficiently while maintaining full regulatory compliance."
    },
    team: [],  // Team members would be added here
    values: [
      {
        title: "Financial Innovation",
        description: "Pushing the boundaries of what's possible in corporate finance through blockchain technology"
      },
      {
        title: "Regulatory Compliance",
        description: "Ensuring all solutions meet or exceed regulatory requirements"
      },
      {
        title: "Technological Excellence",
        description: "Building robust, secure, and scalable blockchain solutions"
      },
      {
        title: "Market Efficiency",
        description: "Reducing friction and costs in capital markets through automation"
      }
    ]
  },
  solutionsPage: {
    title: "Our Solutions",
    subtitle: "Innovative Blockchain Solutions for Modern Finance",
    content: "Discover our suite of blockchain-based financial products designed to revolutionize corporate finance and equity trading."
  },
  technologyPage: {
    title: "Our Technology",
    subtitle: "Built on Ethereum, Secured by Innovation",
    markdown: "# Blockchain Technology\n\nOur solutions leverage the latest in Ethereum blockchain technology to provide secure, transparent, and efficient financial services."
  },
  homePage: {
    hero: {
      title: "Revolutionizing Corporate Finance",
      highlight: "Through Blockchain Innovation",
      description: "Access capital markets efficiently with our blockchain-based deferred revenue tokens and regulated equity trading solutions."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};

export default CTIOConfig;
